<template>
    <div id="container">
        <div class="vx-card p-6">
            <div class="vx-row ml-auto">
                <h4>ĐỐI SOÁT DỮ LIỆU</h4>
            </div>
            <vs-divider />
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-col w-full">
                        <vs-table :noDataText="'Không có dữ liệu'" :data="fileData"
                            max-items="12">
                            <template slot="thead">
                                <vs-th style="font-size:13px" class="bg-primary text-white" sort-key="email"
                                    v-for="(header, indexHeader) in headerTables" :key="indexHeader">
                                    <span>{{ header }}</span>
                                </vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :data="file" :key="fileIndex" v-for="(file, fileIndex) in data">
                                    <vs-td>
                                        {{ file.userId }}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.username }}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.createDate }}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.createdDate }}
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileInput: [],
            fileServer: [],
            headerTables: [
                
            ]
        }
    },

    created() {
        let fileId = this.$route.query.id
        this.$crm.post(`/history/find-by-id/${fileId}`).then(response => {
            this.fileInput = response.data.metaDataInput
            this.fileServer = response.data.metaDataResult
        })
    },
}
</script>